

app.controller('cartCtrl',function($scope, $http){
    $scope.add_to_cat = function(id){
        $http.get('/cart_count').success(function(e){
            $("#cart").text(e);
        })
    }
    $scope.add_to_cat();
})



app.controller('cartlistCtrl',function($scope, $http){
    $scope.get_catList = function(){
        $http.get('/get_cat_list').success(function(e){
            $scope.cart = e.cart;
            $scope.detail = e.detail;
        })
    }
    $scope.get_catList();

    $scope.add_to_cat = function(id){
        $http.get('/cart_count').success(function(e){
            $("#cart").text(e);
        })
    }
    $scope.add_to_cat();

    $scope.update_cart = function(i){
        var qty = $scope.cart[i].quantity;
        if(qty > $scope.cart.prev){
            var nq = 1;
        }else{
            var nq = -1;
        }
        $scope.cart.prev = qty;
        var id = $scope.cart[i].product_id;
        $http.post('/update_cart',{'id':id,'qty':nq}).success(function(){
            $scope.get_catList();
        })
    }

    $scope.remove_cart = function(i){
        var id = $scope.cart[i].product_id;
        $http.post('/remove_cart',{'id':id}).success(function(){
            $scope.cart.splice(i, 1);
        })
    }
})

